import Head from 'next/head'

import Post from '../components/post'

export async function getStaticProps() {
  // fetch list of posts
  const response = await fetch(
    'https://jsonplaceholder.typicode.com/posts?_page=1'
  )
  const postList = await response.json()
  return {
    props: {
      postList,
    },
  }
}

export default function IndexPage({ postList }) {
  return (
    <main>
      <Head>
        <title>Logistiikka-alan henkilöstöpalveluyritys | Valpro Logistics | Vantaa</title>
        <meta name="description" content="Olemme logistiikka-alaan erikoistunut henkilöstöpalveluyritys. Yhdistämme alan ammattilaiset asiakasyrityksiimme nopeasti ja luotettavasti |  Valpro Logistics" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <h1>List of posts</h1>

      <section>
        {postList.map((post) => (
          <Post {...post} key={post.id} />
        ))}
      </section>
    </main>
  )
}
